import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { isLanguageSupported } from './isLanguageSupported.ts'

export const useNavigateToLanguage = () => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (Array.isArray(i18n.options.supportedLngs)) {
      const pathLanguage = location.pathname.split('/').at(1)
      const pathLanguageSupported = isLanguageSupported(i18n, pathLanguage)

      // special case for en since it is at root
      if (i18n.language === 'en' && pathLanguage === 'en') {
        // if we are at /en/ then navigate back to root
        void navigate(`/${location.pathname.split('/').slice(2).join('/')}`, { replace: true })
        return
      }
      if (pathLanguage !== i18n.language) {
        if (pathLanguageSupported) {
          const newPath = location.pathname.replace(`/${pathLanguage}`, (i18n.language === 'en') ? '' : `/${i18n.language}`)
          void navigate(newPath, { replace: true })
        } else if (i18n.language !== 'en') {
          if (i18n.language === 'en') {
            // if we are switching to en, remove the language path
            void navigate(`/${location.pathname}`, { replace: true })
          } else {
            // replace the language path
            void navigate(`/${i18n.language}${location.pathname}`, { replace: true })
          }
        }
      }
    }
  }, [i18n.language, i18n.options.supportedLngs])
}
