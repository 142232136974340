import { LaunchRounded } from '@mui/icons-material'
import type { SxProps } from '@mui/material'
import {
  alpha,
  Box,
  Container,
  Grid2,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import type { ComponentProps, ReactNode } from 'react'
import React from 'react'

import { AppBarOffset } from '../AppBarOffset.tsx'
import { ButtonSection } from './ButtonSection.tsx'

export interface BasicHeroProps extends FlexBoxProps {
  backgroundImage?: string
  button1Props?: ButtonExProps
  button1Text?: string
  button2Props?: ButtonExProps
  button2Text?: string
  desc: string
  extras?: ReactNode
  heroImage?: string
  heroImageProps?: React.CSSProperties
  subLinkSection?: ReactNode
  subtitle?: ReactNode
  sx?: Record<string, string>
  title: string
}

const HeroButtons: React.FC<{
  button1Props?: ButtonExProps
  button1Text?: string
  button2Props?: ButtonExProps
  button2Text?: string
}> = ({
  button1Text,
  button1Props,
  button2Text,
  button2Props,
}) => (
  <Stack
    sx={{ flexDirection: { md: 'row', xs: 'column' } }}
    gap={1}
    marginY={1}
  >
    <ButtonSection
      endIcon={
        button1Props?.['href'] === undefined ? undefined : <LaunchRounded />
      }
      buttonText={button1Text}
      onClick={() => {
        if (button1Props?.['to']?.toString().startsWith('#')) {
          document.querySelector(button1Props?.['to'].toString())?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }}
      {...button1Props}
    />
    <ButtonSection
      variant="outlined"
      endIcon={
        button2Props?.['href'] === undefined ? undefined : <LaunchRounded />
      }
      buttonText={button2Text}
      onClick={() => {
        if (button2Props?.['to']?.toString().startsWith('#')) {
          document.querySelector(button2Props?.['to'].toString())?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }}
      {...button2Props}
    />
  </Stack>
)

interface HeroContentProps extends FlexBoxProps {
  buttonsProps: ComponentProps<typeof HeroButtons>
  desc: string
  extras?: ReactNode
  subLinkSection?: ReactNode
  subtitle?: ReactNode
  sx?: SxProps
  title: string
}
const HeroContent: React.FC<HeroContentProps> = ({
  title, desc, subtitle, subLinkSection, extras, buttonsProps, sx, ...props
}) => {
  return (
    <FlexGrowCol
      paddingY={3}
      sx={{ ...sx }}
      {...props}
    >
      {title && (
        <Typography
          variant="h1"
          gutterBottom
          sx={{ textAlign: { md: 'left', xs: 'center' }, fontSize: { md: '3.5rem', lg: '4rem' } }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        typeof 'string'
          ? (
              <Typography
                variant="h6"
                color="secondary"
                gutterBottom
                sx={{ textAlign: { md: 'left', xs: 'center' } }}
              >
                {subtitle}
              </Typography>
            )
          : subtitle
      )}
      {desc && (
        <Typography
          variant="body1"
          component="h2"
          gutterBottom
          sx={{ textAlign: { md: 'left', xs: 'center' } }}
        >
          {desc}
        </Typography>
      )}
      <HeroButtons {...buttonsProps} />
      {subLinkSection}
      {extras}
    </FlexGrowCol>
  )
}

export const XyoBasicHero: React.FC<BasicHeroProps> = ({
  backgroundImage,
  title,
  subtitle,
  desc,
  button1Text,
  button2Text,
  button1Props,
  button2Props,
  extras,
  subLinkSection,
  heroImage,
  heroImageProps,
  sx,
}) => {
  const theme = useTheme()

  return (
    <Box>
      <FlexGrowCol
        position="relative"
        sx={{
          backgroundImage: { md: backgroundImage ? `url(${backgroundImage})` : '', xs: 'none' },
          backgroundPosition: 'center left',
          backgroundSize: { md: 'cover', xs: '100%' },
          justifyContent: 'flex-start',
          paddingTop: { md: theme.spacing(0), xs: theme.spacing(2) },
          marginBottom: { xs: heroImage ? 7 : 0, md: 0 },
          minHeight: { xs: heroImage ? theme.spacing(7) : 0, md: 'auto' },
          ...sx,
        }}
        style={{ backgroundRepeat: 'no-repeat' }}
      >
        <AppBarOffset />
        {heroImage
          && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '-15%',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                height: { xs: '300px' },
                opacity: 0.8,
                backgroundImage: {
                  xs: `linear-gradient(to bottom, 
              ${alpha(theme.palette.background.default, 1)},
              ${alpha(theme.palette.background.default, 0.9)},
              ${alpha(theme.palette.background.default, 0.2)},
              ${alpha(theme.palette.background.default, 0)}), 
              url(${heroImage})`,
                  md: 'none',
                },
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          )}
        <Container maxWidth="lg" sx={{ overflow: 'visible', paddingBottom: heroImage ? 3 : 0 }}>
          <Grid2
            container
            sx={{
              alignItems: 'stretch', justifyContent: { xs: 'center', md: 'flex-start' }, position: 'relative',
            }}
          >
            <HeroContent
              title={title}
              desc={desc}
              subtitle={subtitle}
              subLinkSection={subLinkSection}
              extras={extras}
              buttonsProps={{
                button1Text,
                button2Text,
                button1Props,
                button2Props,
              }}
              sx={{
                alignItems: { md: 'flex-start', xs: 'center' },
                zIndex: 1,
                marginTop: { md: 3, xs: 2 },
                maxWidth: {
                  xs: '100%', md: '50%', lg: heroImage === undefined ? '70%' : '40%',
                },
                textShadow: ` 0 0 20px ${alpha(theme.palette.background.default, 0.8)}`,
              }}
            />
            {heroImage && (
              <FlexGrowCol
                position="absolute"
                sx={{
                  backgroundImage: {
                    lg: `url(${heroImage})`,
                    md: `linear-gradient(to left, ${alpha(theme.palette.background.default, 0)},
                    ${alpha(theme.palette.background.default, 0.1)}, ${alpha(theme.palette.background.default, 0.9)}), url(${heroImage})`,
                    xs: 'none',
                  },
                  backgroundPosition: 'bottom',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: {
                    lg: '700px', md: '600px', sm: '500px', xs: '100%',
                  },
                  minHeight: { md: '600px', sm: '500px' },
                  height: '100%',
                  opacity: { xs: 0.4, sm: 1 },
                  right: { sm: 0 },
                  bottom: { sm: 0 },
                  ...heroImageProps,
                }}
              />
            )}
          </Grid2>
        </Container>
      </FlexGrowCol>
    </Box>
  )
}
