import {
  alpha, Box, styled,
} from '@mui/material'

export const BackgroundGradientBox1 = styled(Box, { name: 'BackgroundGradientBox1' })(({ theme }) => ({
  background:
  `radial-gradient(circle 400px at 5% 800px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.15)}, ${alpha(
    theme.palette.background.default,
    0.1,
  )}),
          radial-gradient(circle 900px at -20% 1400px, ${alpha('#FF5BDC', 0.09)}, ${alpha(theme.palette.background.default, 0.1)}),
          radial-gradient(circle 600px at 90% 2000px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.2)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}),radial-gradient(circle 600px at 50% 1800px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.25)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}),
          radial-gradient(circle 500px at 68% 1000px, ${alpha('#FF5BDC', 0.15)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}),radial-gradient(circle 900px at 90% 3000px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.25)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}), radial-gradient(circle 300px at 99% 700px, ${alpha('#FF5BDC', 0.25)}, ${alpha(theme.palette.background.default, 0.1)}), 
  radial-gradient(circle 400px at 5% 3100px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.15)}, ${alpha(
    theme.palette.background.default,
    0.1,
  )}),
          radial-gradient(circle 900px at -20% 4400px, ${alpha('#FF5BDC', 0.09)}, ${alpha(theme.palette.background.default, 0.1)}),
          radial-gradient(circle 600px at 90% 5000px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.2)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}),radial-gradient(circle 600px at 50% 4800px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.25)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}),
          radial-gradient(circle 900px at 68% 3800px, ${alpha('#FF5BDC', 0.15)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}),radial-gradient(circle 900px at 90% 6000px, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.25)}, ${alpha(
    theme.palette.background.default,
    0.2,
  )}), radial-gradient(circle 300px at 95% 3300px, ${alpha('#FF5BDC', 0.25)}, ${alpha(theme.palette.background.default, 0.1)})`,
})) as typeof Box
