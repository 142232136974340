import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import {
  Box,
  ClickAwayListener,
  Divider,
  MenuList,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol, FlexGrowRow } from '@xylabs/react-flexbox'
import React from 'react'

import { useNavbarData } from '../NavData/index.ts'
import { DropdownCta } from './DropdownCta.tsx'
import { NavBarMenuItem } from './NavBarMenuItem.tsx'
import type { DropdownSectionProps } from './props/index.ts'
import { StyledDropdownSection } from './StyledDropdownSection.tsx'

export const DropdownSection: React.FC<DropdownSectionProps> = ({
  data,
  dropdownId,
  open,
  openController,
  placement,
}) => {
  const theme = useTheme()
  const NavbarData = useNavbarData()
  const {
    name, to, colItems, colItems2, cta,
  } = data

  return (
    <ClickAwayListener onClickAway={() => openController([dropdownId, false])}>
      <div>
        <StyledDropdownSection
          role="menu"
          id={`${name}-menu`}
          aria-labelledby={`${name}button`}
          placement={placement}
          open={open}
          disableHoverListener
          disableTouchListener
          title={(
            <FlexGrowRow alignItems="stretch" justifyContent="stretch" overflow="hidden">
              {cta && (
                <DropdownCta {...cta} />
              )}
              <Box sx={{ display: 'flex', width: '100%' }}>
                <MenuList sx={{ flex: 1, width: colItems2 === undefined ? '100%' : '50%' }} role="presentation">
                  {colItems?.title
                    ? (
                        <FlexCol alignItems="flex-start">
                          <Typography pl={1.3} variant="h6" color="secondary">{colItems?.title}</Typography>
                        </FlexCol>
                      )
                    : null}
                  {colItems?.items?.map((item, index) => (
                    <NavBarMenuItem
                      key={index}
                      index={index}
                      item={item}
                      totalSectionItems={colItems.items}
                      navCategory={name}
                      openController={openController}
                    />
                  ))}
                </MenuList>
                {colItems2
                  ? (
                      <MenuList sx={{ flex: 1, width: '50%' }} role="presentation">
                        {colItems2?.title
                          ? (
                              <FlexCol alignItems="flex-start">
                                <Typography pl={1.3} variant="h6" color="secondary">{colItems2?.title}</Typography>
                              </FlexCol>
                            )
                          : null}
                        {colItems2?.items?.map((item, index) => (
                          <NavBarMenuItem
                            key={index}
                            index={index}
                            item={item}
                            totalSectionItems={colItems2.items}
                            navCategory={name}
                            openController={openController}
                          />
                        ))}
                      </MenuList>
                    )
                  : null}
              </Box>
            </FlexGrowRow>
          )}
        >
          <Stack display="flex" flexDirection="row" alignItems="center">
            <ButtonEx
              variant="text"
              endIcon={open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
              disableTouchRipple
              paddingY={1}
              paddingX={1.5}
              aria-controls={`${name}-menu`}
              id={`${name}button`}
              aria-haspopup="menu"
              aria-expanded={open}
              name={`Nav Section ${NavbarData[dropdownId].name} Dropdown Opener`}
              placement={`Nav Section ${NavbarData[dropdownId].name} Dropdown Opener`}
              sx={{
                '&:hover': {
                  filter: 'none',
                  color: theme.palette.secondary.main,
                  cursor: to ? 'pointer' : 'default',
                },
              }}
              onClick={() => openController([dropdownId, !open])}
            >
              <Typography
                variant="button"
                color={theme.palette.text.primary}
                style={{
                  fontWeight: 'normal',
                  textDecoration: open ? `underline 1.5px ${theme.palette.secondary.main}` : 'none',
                  textUnderlineOffset: open ? '5px' : 'auto',
                }}
              >
                {name}
              </Typography>
            </ButtonEx>
          </Stack>
        </StyledDropdownSection>
      </div>
    </ClickAwayListener>
  )
}
