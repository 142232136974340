export const supportedLanguages = [
  'en',
  'es',
  'de',
  'zh',
  'ja',
  'ko',
  'id',
  'fr',
  'ro',
  'ru',
  'fil',
  'ar',
  'am',
  'hi',
  'it',
  'nl',
  'pt',
  'sw',
  'tr',
  'uk',
  'vi',
  'fa',
  'af',
] as const

export type SupportedLanguage = typeof supportedLanguages[number]

export interface Language {
  code: SupportedLanguage
  flag: string
  label: string
}

export const allLanguages: Language[] = [
  {
    code: 'en',
    label: 'English',
    flag: '🇺🇸',
  },
  {
    code: 'am', label: 'አማርኛ', flag: '🇪🇹',
  },
  {
    code: 'ar', label: 'العربية', flag: '🇦🇪',
  },
  {
    code: 'de', label: 'Deutsch', flag: '🇩🇪',
  },
  {
    code: 'es', label: 'Español', flag: '🇪🇸',
  },
  {
    code: 'fil', label: 'Filipino', flag: '🇵🇭',
  },
  {
    code: 'fr', label: 'Français', flag: '🇫🇷',
  },
  {
    code: 'hi', label: 'हिन्दी', flag: '🇮🇳',
  },
  {
    code: 'id', label: 'Bahasa', flag: '🇮🇩',
  },
  {
    code: 'it', label: 'Italiano', flag: '🇮🇹',
  },
  {
    code: 'ja', label: '日本語', flag: '🇯🇵',
  },
  {
    code: 'ko', label: '한국어', flag: '🇰🇷',
  },
  {
    code: 'nl', label: 'Nederlands', flag: '🇳🇱',
  },
  {
    code: 'pt', label: 'Português', flag: '🇵🇹',
  },
  {
    code: 'zh', label: '中文', flag: '🇨🇳',
  },
  {
    code: 'ro', label: 'Română', flag: '🇷🇴',
  },
  {
    code: 'ru', label: 'Русский', flag: '🇷🇺',
  },
  {
    code: 'sw', label: 'Kiswahili', flag: '🇹🇿',
  },
  {
    code: 'tr', label: 'Türkçe', flag: '🇹🇷',
  },
  {
    code: 'uk', label: 'Українська', flag: '🇺🇦',
  },
  {
    code: 'vi', label: 'Tiếng Việt', flag: '🇻🇳',
  },
  {
    code: 'fa', label: 'فارسی', flag: '🇮🇷',
  },
  {
    code: 'af', label: 'Afrikaans', flag: '🇿🇦',
  },
]
