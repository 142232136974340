import {
  CheckCircleOutline, ErrorOutline, KeyboardArrowRightRounded,
} from '@mui/icons-material'
import type { TextFieldProps } from '@mui/material'
import {
  Box, Fade, InputAdornment, Stack, TextField, Typography,
} from '@mui/material'
import { forget } from '@xylabs/forget'
import { ButtonEx } from '@xylabs/react-button'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import emailRegEx from 'email-regex-safe'
import type { ReactNode } from 'react'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import type { XyoNewsletterSignupFields } from '../hooks/index.ts'
import { useXyoUserEvents } from '../hooks/index.ts'
import { iterableTrackEvent } from '../lib/index.ts'

export type EmailNewsletterTextFieldProps = TextFieldProps & {
  checkValidEmail?: boolean | null
  displayOnComplete?: ReactNode
  onEnter?: () => void
}

export const EmailNewsletterTextField: React.FC<EmailNewsletterTextFieldProps> = ({ displayOnComplete }) => {
  const [customerEmail, setCustomerEmail] = useState<string | null>('')
  const [complete, setComplete] = useState(false)
  const [checkValidEmail, setCheckValidEmail] = useState<boolean | null>(null)
  const userEvents = useXyoUserEvents()
  const { t } = useTranslation()
  const validEmailRegex = (email: string) => {
    return emailRegEx({ exact: true }).test(email)
  }

  const emailValidate = (value: string) => {
    if (validEmailRegex(value)) {
      setCheckValidEmail(true)
    } else {
      setCheckValidEmail(false)
    }
  }

  const handleChange = (email: string) => {
    if (email.length > 0) {
      setCustomerEmail(email)
      emailValidate(email)
    } else {
      setCustomerEmail('')
      emailValidate(email)
    }
    return true
  }
  return (
    <FlexGrowCol alignItems="flex-start">
      <Box paddingBottom={2}>
        {complete
          ? <Typography>{t('common:thankYouForSignUp')}</Typography>
          : (
              <Fade in={!complete} unmountOnExit mountOnEnter appear={false}>
                <Stack gap={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                  <TextField
                    type="email"
                    variant="outlined"
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="start">
                            <CheckCircleOutline sx={{ display: checkValidEmail === null ? 'block' : 'hidden', visibility: 'hidden' }} />
                            <CheckCircleOutline
                              color="success"
                              fontSize="medium"
                              sx={{ position: 'absolute', visibility: checkValidEmail === true ? 'visible' : 'hidden' }}
                            />
                            <ErrorOutline
                              color="error"
                              fontSize="medium"
                              sx={{ position: 'absolute', visibility: checkValidEmail === false ? 'visible' : 'hidden' }}
                            />
                          </InputAdornment>
                        ),
                      },
                    }}
                    size="small"
                    value={customerEmail ?? ''}
                    onChange={event => handleChange?.(event.target.value)}
                  />
                  <ButtonEx
                    color="neutral"
                    variant="outlined"
                    endIcon={<KeyboardArrowRightRounded />}
                    inputMode="email"
                    onClick={() => {
                      if (customerEmail && checkValidEmail) {
                        forget(
                          iterableTrackEvent({
                            dataFields: { url: globalThis.location.href },
                            email: customerEmail?.toLocaleLowerCase(),
                            eventName: 'subscribeXyoNewsletter',
                          }),
                        )
                        const fields: XyoNewsletterSignupFields = {
                          email: customerEmail?.toLocaleLowerCase(),
                          url: globalThis.location.href,
                        }
                        forget(userEvents.xyoNewsletterSignup(fields))
                        setComplete(true)
                      } else {
                        setCheckValidEmail(false)
                      }
                    }}
                  >
                    <Trans i18nKey="common:signUp" />
                  </ButtonEx>
                </Stack>
              </Fade>
            )}
      </Box>
      {displayOnComplete && complete === true ? displayOnComplete : null}
    </FlexGrowCol>
  )
}
