import type { AnimatedProps } from '@react-spring/web'
import { animated } from '@react-spring/web'
import type { HTMLAttributes, ReactNode } from 'react'
import React from 'react'

type AnimatedDivProps = AnimatedProps<HTMLAttributes<HTMLDivElement>> & {
  children?: ReactNode
}

export const AnimatedDiv: React.FC<AnimatedDivProps> = (props) => {
  return <animated.div {...props} />
}
