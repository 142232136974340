import type { SlideProps } from '@mui/material'
import {
  Box,
  Card,
  Dialog,
  Fade,
  Slide, Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import * as React from 'react'

import { SlideInArrow } from '../../common/index.ts'
import type { DropdownSectionDataProps } from '../Dropdown/index.ts'
import { MobileNavSectionDialogRender } from './MobileNavSectionRender.tsx'
import { SectionAppBar } from './SectionAppBar.tsx'

const Transition: React.FC<SlideProps> = (
  { ref, ...props },
) => {
  return <Slide direction="right" ref={ref} {...props} />
}

export interface MobileNavSectionDialogProps extends DropdownSectionDataProps {
  closeAllDialogs: () => void
}

export const MobileNavSectionDialog: React.FC<MobileNavSectionDialogProps> = ({
  closeAllDialogs,
  colItems,
  colItems2,
  cta,
  name,
}) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleGoBack = () => {
    setOpen(false)
  }

  const handleAllClose = () => {
    setOpen(false)
    closeAllDialogs()
  }

  return (
    <React.Fragment>
      <LinkEx component="button" color="inherit" onClick={handleClickOpen}>
        <Typography fontWeight={theme.typography.fontWeightMedium} variant="h3">{name}</Typography>
      </LinkEx>
      <Dialog
        fullScreen
        open={open}
        onClose={handleAllClose}
        slots={{
          backdrop: Fade,
          transition: Transition,
        }}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.paper, backgroundImage: 'none', paddingLeft: 2, paddingRight: 2,
          },
        }}
      >
        <SectionAppBar handleGoBack={handleGoBack} handleAllClose={handleAllClose} name={name} />
        <Stack gap={3} flexDirection="column" alignItems="stretch">
          {colItems ? <MobileNavSectionDialogRender items={colItems.items} title={colItems.title} /> : null}
          {colItems2 ? <MobileNavSectionDialogRender items={colItems2.items} title={colItems2.title} /> : null}
          {cta && (
            <Fade in timeout={2000}>
              <LinkEx to={cta.ctaLink}>
                <Card
                  sx={{ bgcolor: theme.palette.primary.main }}
                >
                  <FlexGrowCol
                    padding={1}
                    height="200px"
                    overflow="hidden"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    bgcolor={theme.palette.primary.main}
                  >
                    <Fade in={true} timeout={1000}>
                      <Slide in={true} direction="right" timeout={1000}>
                        <Box maxHeight="100%">{cta?.ctaIcon}</Box>
                      </Slide>
                    </Fade>
                    <Typography variant="body2" color={theme.palette.primary.contrastText}>
                      {cta?.ctaTitle}
                    </Typography>
                    <Typography variant="caption" color={theme.palette.primary.contrastText} display="flex" alignItems="center">
                      <span>
                        {cta?.ctaText}
                        <SlideInArrow inTrigger={true} />
                      </span>
                    </Typography>
                  </FlexGrowCol>
                </Card>
              </LinkEx>
            </Fade>
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  )
}
